import React from 'react';

interface CalloutProps {
    text: string;
    backgroundColor?: string;
    textColor?: string;
}

const Callout: React.FC<CalloutProps> = ({
    text,
    backgroundColor = 'bg-dark',
    textColor = 'text-white'
}) => {
    return (
        <div className={`w-full py-8 ${backgroundColor}`}>
            <div className="container w-3/4 mx-auto px-4">
                <h2 className={`text-center text-2xl md:text-2xl lg:text-2xl font-bold ${textColor}`}>
                    {text}
                </h2>
            </div>
        </div>
    );
};

export default Callout;